import request from '@/utils/request'

// 查询客户套餐
export function findAgentsProducts(params) {
  return request({
    url: `/customer/agents_products`,
    method: 'get',
    params: params
  })
}

// 查询单个客户套餐表单值
export function findAgentsProductForm(id) {
  return request({
    url: `/customer/agents_products/${id}/form`,
    method: 'get'
  })
}

// 更新客户套餐
export function updateAgentsProduct(id, data) {
  return request({
    url: `/customer/agents_products/${id}`,
    method: 'put',
    data
  })
}

// 查询单个套餐
export function findAgentsProduct(id) {
  return request({
    url: `/customer/agents_products/${id}`,
    method: 'get'
  })
}

// 查询商品
export function findGoods(params) {
  return request({
    url: `/customer/goods`,
    method: 'get',
    params: params
  })
}

// 查询商品详情
export function findGoodsInfo(id) {
  return request({
    url: `/customer/goods/${id}`,
    method: 'get'
  })
}

// 客户套餐选项数据
export function findAgentsProductsOptions(params) {
  return request({
    url: `/agents_products/options`,
    method: 'get',
    params: params
  })
}

// 分销商查看企业客户套餐选项数据
export function findAgencyAgentsProductsOptions(params) {
  return request({
    url: `/customer/agents_products/agency/by_top_agent/options`,
    method: 'get',
    params: params
  })
}

// 分销商查询企业客户套餐
export function findAgencyAgentsProductsByTopAgent(params) {
  return request({
    url: `/customer/agents_products/agency/by_top_agent`,
    method: 'get',
    params: params
  })
}

// 分销商查询企业客户套餐表单
export function findAgencyAgentsProductByTopAgentForm(id) {
  return request({
    url: `/customer/agents_products/agency/by_top_agent/${id}/form`,
    method: 'get'
  })
}

// 分销商更新企业客户套餐
export function updateAgencyAgentsProductByTopAgent(id, data) {
  return request({
    url: `/customer/agents_products/agency/by_top_agent/${id}`,
    method: 'patch',
    data
  })
}

// 分销商查看企业客户套餐详情
export function findAgencyAgentsProductByTopAgent(id) {
  return request({
    url: `/customer/agents_products/agency/by_top_agent/${id}`,
    method: 'get'
  })
}

// 分销商批量更新企业客户套餐有效性
export function batchUpdateAgencyAgentsProductsEffectiveByTopAgent(data) {
  return request({
    url: `/customer/agents_products/agency/by_top_agent/effective/batch`,
    method: 'patch',
    data
  })
}

// 查询可导入的客户套餐
export function findImportableAgentsProducts(params) {
  return request({
    url: `/customer/agents_products/agency/by_top_agent/importable`,
    method: 'get',
    params: params
  })
}

// 导入客户套餐
export function importAgentsProducts(data) {
  return request({
    url: `/customer/agents_products/agency/by_top_agent/import`,
    method: 'post',
    data
  })
}

// 企业客户查询自己的套餐
export function findAgencyAgentsProductsBySelf(params) {
  return request({
    url: `/customer/agents_products/agency/by_self`,
    method: 'get',
    params: params
  })
}

// 企业客户查询自己的套餐表单
export function findAgencyAgentsProductBySelfForm(id) {
  return request({
    url: `/customer/agents_products/agency/by_self/${id}/form`,
    method: 'get'
  })
}

// 企业客户更新自己的套餐
export function updateAgencyAgentsProductBySelf(id, data) {
  return request({
    url: `/customer/agents_products/agency/by_self/${id}`,
    method: 'patch',
    data
  })
}

// 企业客户查看自己的套餐详情
export function findAgencyAgentsProductBySelf(id) {
  return request({
    url: `/customer/agents_products/agency/by_self/${id}`,
    method: 'get'
  })
}
