import request from '@/utils/request'

// 运营商种类选项数据
export function findCarrierTypeOptions() {
  return [
    { label: '中国移动', value: 'china_mobile' },
    { label: '中国联通', value: 'china_unicom' },
    { label: '中国电信', value: 'china_telecom' },
    { label: '中国广电', value: 'china_broadnet' },
    { label: '国际运营商', value: 'international_carrier' }
  ]
}

// 用户业务模式选项数据
export function findUserBusinessTypeOptions() {
  return [
    { name: '自定义自然月', value: 'custom_month' },
    { name: '自定义天数', value: 'custom_day' },
    { name: '流量共享', value: 'traffic_share' },
    { name: '流量共享(总池)', value: 'traffic_share_package' },
    { name: '空白卡', value: 'merchant_sales' }
  ]
}

// 套餐类型选项数据
export function findProductTypeOptions() {
  return [
    { name: '通用套餐', value: 'common' },
    { name: '流量加油包', value: 'addon_flow' },
    { name: '语音加油包', value: 'addon_voice' }
  ]
}

// 卡片材质选项数据
export function findCardShapeOptions(cardTypeId) {
  return request({
    url: `/card_types/${cardTypeId}/card_shape/options`,
    method: 'get'
  })
}

// 生命周期选项数据
export function findLifeCycleOptions() {
  return request({
    url: `/life_cycle/options`,
    method: 'get'
  })
}

// 联网状态选项数据
export function findNetStatusOptions() {
  return request({
    url: `/net_status/options`,
    method: 'get'
  })
}

// 批量操作类型选项数据
export function findBatchOperationTypeOptions() {
  return request({
    url: `/batch_operation_records/operation_type/options`,
    method: 'get'
  })
}

// 批量操作状态选项数据
export function findBatchOperationStatusOptions() {
  return [
    { label: '待处理', value: 'pending' },
    { label: '进行中', value: 'processing' },
    { label: '成功', value: 'success' },
    { label: '失败', value: 'failed' }
  ]
}

